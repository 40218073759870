import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useProcessList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { getItemsWithAggregate, updateItem, updateItems, ObjectId } = realmConnection()
  const userData = store.state?.userStore?.userData
  const processes = ref([])
  const collection = 'process'

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      { key: 'code', label: i18n.t('message.tableHeader.code'), sortable: true },
      { key: 'name', label: i18n.t('message.tableHeader.name'), sortable: true },
      { key: 'crimes', label: i18n.t('label.crimes'), sortable: true },
      { key: 'controlsData', label: i18n.t('message.tableHeader.activities') },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })

  const totalProcesses = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)

  const fetchProcesses = async () => {
    isLoading.value = true

    try {
      const filter = {
        client_id: ObjectId(userData.client.$oid),
        deleted: { $ne: true }
      }
      
      if (searchQuery.value) {
        filter.$or = [
          { name: { $regex: searchQuery.value, $options: 'i' } },
          { code: { $regex: searchQuery.value, $options: 'i' } }
        ]
      }
    
      const pipeline = [
        { $match: filter },
        {
          $lookup: {
            from: "crime",
            localField: "crimes",
            foreignField: "_id",
            as: "crimesData",
            pipeline: [ { $project: { _id: 0, name: 1 } } ]
          }
        },
        {
          $lookup: {
            from: "control",
            localField: "controls",
            foreignField: "_id",
            as: "controlsData",
            pipeline: [ { $project: { name: 1 } } ]
          }
        },
        // This next stage sorts the controlsData array in the same order as the controls array
        {
          $addFields: {
            controlsData: {
              $map: {
                input: "$controls",
                as: "controlId",
                in: { $arrayElemAt: [ "$controlsData", { $indexOfArray: ["$controlsData._id", "$$controlId"] } ] }
              }
            }
          }
        },
        { $project: { name: 1, code: 1, crimesData: 1, controlsData: 1 } },
        { $facet: {
            count: [ { $count: 'totalItems' } ],
            pagination: [
              { $sort: { [sortBy.value]: isSortDirDesc.value ? -1 : 1, _id: isSortDirDesc.value ? -1 : 1 } },
              { $skip: (currentPage.value * perPage.value) - perPage.value || 0 },
              { $limit: perPage.value || 10 }
            ]
          }
        }
      ]
    
      const items = await getItemsWithAggregate({ collection, pipeline })

      items[0]?.pagination?.forEach(e => {
        e.crimes = e.crimesData?.map(c => c.name).join(" / ") || ''
      })

      processes.value = items[0]?.pagination || []
      totalProcesses.value = items[0]?.count[0]?.totalItems || 0
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.process_list_fetch_error'))
    } finally {
      isLoading.value = false
    }
  }

  const deleteProcess = async (id) => {
    try {
      const query = { _id: ObjectId(id) }
      const payload = { deleted: true }
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.process_remove'))
      fetchProcesses()

      // Delete confirmation plannings associated to this process
      await updateItems({ collection: 'confirmation_planning', query: { process: ObjectId(id) }, action })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.process_remove_error'))
    }
  }

  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (processes.value.length ? 1 : 0)
    const to = processes.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalProcesses.value })
  })

  watch([currentPage, perPage], () => {
    fetchProcesses()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchProcesses()
  })

  return {
    fetchProcesses,
    tableColumns,
    perPage,
    currentPage,
    totalProcesses,
    showingMessage,
    searchQuery,
    sortBy,
    isSortDirDesc,
    processes,
    deleteProcess,
    isLoading,
    perPageOptions,
  }
}
